import React, { Fragment } from 'react';
import { I18nText, LabelHint } from '@wtag/react-comp-lib';
import CheckBox from '@wtag/rcl-check-box';
import Tooltip from '@wtag/rcl-tooltip';
import Icon from '@wtag/rcl-icon';
import PropTypes from 'prop-types';

const FlightSearchFilter = ({
  searchInputConfiguration,
  flexible,
  onlyFlexibleFlight,
  exchange,
  itinerarySearch,
  welltravelChannel,
  wtsEnabled,
  nonStop,
  laymanMode,
  canExcludeWtsContent,
  isPtcSelected,
}) => {
  const { showPlusMinusThreeDays, showOnlyFlexibleFlight, showNonStop } = searchInputConfiguration;

  return (
    <Fragment>
      {laymanMode ? (
        <div className="d-flex flight-form__filters-travelapp flex-wrap">
          {showPlusMinusThreeDays && (
            <CheckBox
              name="flexible"
              onChange={isInputChecked => flexible.onChange(isInputChecked)}
              isChecked={!!flexible.value}
              label={<I18nText id="ibe.search_form.flexibility" returnStringOnly={true} />}
            />
          )}
          {showNonStop && (
            <CheckBox
              label={
                <span>
                  <I18nText id="ibe.search_form.non_stop" returnStringOnly={true} />
                </span>
              }
              name="non-stop"
              onChange={isInputChecked => nonStop.onChange(isInputChecked)}
              isChecked={!!nonStop.value}
            />
          )}
          {showOnlyFlexibleFlight && (
            <CheckBox
              label={
                <span>
                  <I18nText id="ibe.search_form.only_flexible_flight" returnStringOnly={true} />
                </span>
              }
              name="only-flexible"
              onChange={isInputChecked => onlyFlexibleFlight.onChange(isInputChecked)}
              isChecked={!!onlyFlexibleFlight.value}
            />
          )}
          {!exchange.enabled && (
            <div className="d-flex align-center flight-form__filters-travelapp--label-hint">
              <CheckBox
                label={
                  <span>
                    <I18nText
                      id="ibe.search_form.enable_search_itinerary_only"
                      returnStringOnly={true}
                    />
                  </span>
                }
                onChange={isInputChecked => itinerarySearch.onChange(isInputChecked)}
                isChecked={!!itinerarySearch.value}
                name="itinerary-search"
              />
              <Tooltip
                content={
                  <I18nText
                    id="ibe.search_form.search_itinerary_only_hint"
                    returnStringOnly={true}
                  />
                }
                type="inverse"
                size="tiny"
              >
                <Icon name="invalidOutline" size="tiny" />
              </Tooltip>
            </div>
          )}
        </div>
      ) : (
        <div className="d-flex flight-form__filters align-end flex-wrap">
          <div className="d-flex direction-column row-gap-20">
            {showPlusMinusThreeDays && (
              <CheckBox
                name="flexible"
                onChange={isInputChecked => flexible.onChange(isInputChecked)}
                isChecked={!!flexible.value}
                label={<I18nText id="ibe.search_form.flexibility" returnStringOnly={true} />}
              />
            )}
            {showOnlyFlexibleFlight && (
              <CheckBox
                label={
                  <I18nText id="ibe.search_form.only_flexible_flight" returnStringOnly={true} />
                }
                name="only-flexible"
                onChange={isInputChecked => onlyFlexibleFlight.onChange(isInputChecked)}
                isChecked={!!onlyFlexibleFlight.value}
              />
            )}
          </div>
          <div className="d-flex direction-column row-gap-20">
            {showNonStop && (
              <CheckBox
                label={<I18nText id="ibe.search_form.non_stop" returnStringOnly={true} />}
                name="non-stop"
                onChange={isInputChecked => nonStop.onChange(isInputChecked)}
                isChecked={!!nonStop.value}
              />
            )}
            {!exchange.enabled && (
              <div className="d-flex">
                <CheckBox
                  label={
                    <span>
                      <I18nText
                        id="ibe.search_form.enable_search_itinerary_only"
                        returnStringOnly={true}
                      />
                    </span>
                  }
                  onChange={isInputChecked => itinerarySearch.onChange(isInputChecked)}
                  isChecked={!!itinerarySearch.value}
                  name="itinerary-search"
                />
                <LabelHint
                  className="flight-form__filters-hint"
                  hint={
                    <I18nText
                      id="ibe.search_form.search_itinerary_only_hint"
                      returnStringOnly={true}
                    />
                  }
                  size="tiny"
                />
              </div>
            )}
          </div>
          {wtsEnabled && canExcludeWtsContent && (
            <div className="d-flex align-center flight-form__filters--welltravel-channel">
              <CheckBox
                name="exclude-welltravel"
                label={
                  <span>
                    <I18nText id="ibe.search_form.exclude_welltravel" returnStringOnly={true} />
                  </span>
                }
                onChange={() => welltravelChannel.onChange(!welltravelChannel.value)}
                isChecked={!welltravelChannel.value && !isPtcSelected()}
                disabled= {isPtcSelected()}
              />
              <LabelHint
                className="flight-form__filters-hint"
                hint={
                  <I18nText id="ibe.search_form.exclude_welltravel_hint" returnStringOnly={true} />
                }
                size="tiny"
              />
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
};

FlightSearchFilter.defaultProps = {
  searchInputConfiguration: {
    showPlusMinusThreeDays: true,
    showNonStop: true,
    showOnlyFlexibleFlight: true,
  },
  laymanMode: false,
};

FlightSearchFilter.propTypes = {
  searchInputConfiguration: PropTypes.shape({
    showPlusMinusThreeDays: PropTypes.bool,
    showNonStop: PropTypes.bool,
    showOnlyFlexibleFlight: PropTypes.bool,
  }),
  flexible: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.bool,
  }).isRequired,
  onlyFlexibleFlight: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.bool,
  }).isRequired,
  exchange: PropTypes.shape({
    enabled: PropTypes.bool,
  }).isRequired,
  itinerarySearch: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.bool,
  }).isRequired,
  welltravelChannel: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.bool,
  }).isRequired,
  wtsEnabled: PropTypes.bool.isRequired,
  nonStop: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.bool,
  }).isRequired,
  laymanMode: PropTypes.bool,
  canExcludeWtsContent: PropTypes.bool.isRequired,
  isPtcSelected: PropTypes.func.isRequired,
};

export default FlightSearchFilter;
